.Log{

    display: flex;
    justify-content: center;
 margin-left: 30vw;
 margin-top: 10vw;
    width: 40vw;
}
.CardStyle{
    
}
.Logtitle{

    font-size: 20px;
    color: black;
}
.lableName{
    font-size: 17px;
}
@media screen and (max-width: 825px) {

    .CardStyle {


        margin-top: 40%;
        width: 120%;

      
      
    
    }


} 
@media screen and (max-width: 691px) {
    .CardStyle {

        width: 140%;
   
    
    }


} 