.actions {
  background-color: #5d50ff;
  width: 6rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
}
.actions1 {
  background-color: red;
  width: 7rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
}
.actions3 {
  background-color: rgb(42, 42, 42);
  width: 9rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
}
.addbutton {
  background-color: #fff;
  width: 8rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  font-size: 16px;
  border: 2px solid #5d50ff;
  color: #5d50ff;
}
.addbutton:hover {
  background-color: #5d50ff;
  width: 8rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modaltitle {
  font-size: 25px;
  font-weight: 600;
  color: #5d50ff;
}
.buttoninmodal {
  background-color: #5d50ff;
  width: 8rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  font-size: 16px;
  border: 2px solid #5d50ff;
}
.buttoninmodal1 {
  background-color: red;
  width: 8rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  font-size: 16px;
  border: 2px solid red;
}
.custom-box {
  z-index: 0;
}
.inputstyle {
}
.inputstyle {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.green-button {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.red-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.modalview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.selectg {
  margin-bottom: 10px;
  width: 70%;
  height: 2rem;
  border-radius: 2rem;
}
.itembeside {
  display: flex;
  flex-direction: row;
}
.name-column--cell {
  width: 20rem;
}
